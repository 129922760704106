import React from 'react'

import Layout from "../components/layout"
import SEO from "../components/seo"

const OurPartnersPage = () => (

    <Layout pageClass="our-partners-page">
    <SEO title="Experience On Tap - Our Partners" />
    <main className="main">
        <div className="typography inner">

            <h1>Our Partners</h1>
                <div className="paragraph-block">
                    <img src="Easy_HR-logo.jpg" alt="Easy HR logo"></img>
                    <p></p>
                    <p> At EasyHR our purpose is to help leaders and their people reach their potential, and develop highly successful businesses. We help businesses without internal HR resources gain access to the specialist human resources and employment relations skills, support, advice, and resources they need to succeed. At EasyHR we are passionate about developing great leaders and successful teams. We also work alongside our customers to solve any problems that are holding them back from achieving their goals. </p>
                    <p> We provide services across the areas of strategy, employment relations, leadership, business structure, business process and transformational change. Our holistic approach to helping develop great teams and optimise business operations enables businesses to achieve their goals and take advantage of new opportunities. Whether you need help developing strategy, assistance dealing with people related problems, or simply need support to develop your policies and processes, EasyHR has the expertise to help you realise your ambition.</p>
                </div>
            
        </div>
    </main>
    </Layout>

)


export default OurPartnersPage